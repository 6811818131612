<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <div class="search_center">
              部门：
              <DeptCascader
                :placeholder="'请选择部门'"
                :deptList="deptList"
                v-model="parameter.deptId"
              />
            </div>
            <div class="search_center">
              姓名：
              <el-input
                v-model="parameter.userName"
                class="ipt_width"
                placeholder="请输入姓名"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              单据编号：
              <el-input
                v-model="parameter.expenseNumber"
                class="ipt_width expense__width"
                placeholder="请输入单据单编号"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              项目名称：
              <el-select
                v-model="parameter.projectId"
                clearable
                filterable
                placeholder="请选择项目名称"
                class="ipt_width project_width"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.id"
                  :label="item.projectName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="search_center">
              合同名称：
              <el-select
                v-model="parameter.agreementId"
                clearable
                filterable
                placeholder="请选择合同名称"
                class="ipt_width project_width"
              >
                <el-option
                  v-for="item in agreementList"
                  :key="item.id"
                  :label="item.agreementName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="search_center">
              销售方：
              <Dictionary
                :filterable="true"
                class="ipt_width"
                v-model="parameter.companyType"
                code="COMPANY_TYPE_FULL"
                placeholder="请选择销售方"
              />
            </div>
            <div class="search_center">
              购买方：
              <el-input
                v-model="parameter.customersUnit"
                class="ipt_width"
                placeholder="请输入购买方"
                clearable
              >
              </el-input>
            </div>
            <div class="search_center">
              发票种类：
              <Dictionary
                :filterable="true"
                class="ipt_width"
                v-model="parameter.invoiceLines"
                code="INVOICE_LINES"
                placeholder="请选择发票种类"
              />
            </div>
            <div class="search_center">
              发票状态：
              <Dictionary
                :filterable="true"
                class="ipt_width"
                v-model="parameter.offsetStatus"
                code="OFFSET_STATUS"
                placeholder="请选择发票状态"
              />
            </div>
            <div class="search_center">
              <span>流程进度：</span>
              <Dictionary
                v-model="parameter.actStatus"
                class="ipt_width"
                code="INVOICE_APPLICATION_ACT_STATUS"
                placeholder="请选择流程进度"
              />
            </div>
            <div class="search_center">
              申请日期：
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="申请日期（始）"
                end-placeholder="申请日期（末）"
              >
              </el-date-picker>
            </div>
            <div class="search_center">
              <span>单据金额：</span>
              <NumberRangeInput v-model="numberRange" />
            </div>

            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="dialogVisible = true">申请流程</el-button>
          <el-button type="success" style="padding: 0"
            ><a
              style="display: block; height: 40px; width: 100px; line-height: 40px"
              target="_blank"
              href="https://pro.huitukj.com/iip-api/profile/OPERATION_MANUAL/合同发票操作文档.pdf"
              >操作文档</a
            ></el-button
          >
          <el-button
            type="primary"
            icon="el-icon-printer"
            :loading="exportingLoading"
            v-if="permission(['CONTRACT_INVOICE_EXPORT'])"
            @click="exportingFn"
            >导出</el-button
          >
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            v-loading="loading"
            ref="multipleTable"
            tooltip-effect="dark"
            height="string"
            border
            :data="tableData"
          >
            <el-table-column type="index" label="序号" align="center" width="50"></el-table-column>
            <el-table-column
              prop="expenseNumber"
              label="单据编号"
              align="center"
              min-width="160"
            ></el-table-column>
            <el-table-column
              prop="projectName"
              min-width="320"
              sortable
              label="项目名称"
              :show-overflow-tooltip="false"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="agreementName"
              min-width="320"
              sortable
              label="合同名称"
              :show-overflow-tooltip="false"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="createName"
              min-width="120"
              sortable
              label="申请人"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="deptName"
              min-width="110"
              sortable
              label="部门"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="sumCost"
              :show-overflow-tooltip="false"
              min-width="110"
              sortable
              label="开票金额"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.sumCost | applyAmount }}
              </template></el-table-column
            >
            <el-table-column
              prop="sumProceedsAmount"
              :show-overflow-tooltip="false"
              min-width="110"
              sortable
              label="收款金额"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.sumProceedsAmount | applyAmount }}
              </template></el-table-column
            >
            <el-table-column
              prop="companyType"
              :show-overflow-tooltip="false"
              min-width="120"
              sortable
              label="销售方"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.companyType | dict(companyType) }}
              </template></el-table-column
            >
            <el-table-column
              prop="customersUnit"
              :show-overflow-tooltip="false"
              min-width="120"
              sortable
              label="购买方"
              align="center"
            ></el-table-column>

            <el-table-column
              prop="taskDate"
              min-width="110"
              sortable
              label="申请时间"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>
            <el-table-column
              prop="invoiceLines"
              :show-overflow-tooltip="false"
              min-width="120"
              sortable
              label="发票种类"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.invoiceLines | dict(invoiceLines) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="offsetStatus"
              :show-overflow-tooltip="false"
              min-width="120"
              sortable
              label="发票状态"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.offsetStatus | dict(offsetStatus) }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              min-width="150"
              sortable
              prop="statusName"
              label="流程进度"
            >
            </el-table-column>
            <el-table-column label="操作" fixed="right" align="center" min-width="70">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="text_Details_Bgc"
                  @click.stop="handleDetails(scope.row)"
                >
                  详情
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            :total="parameter.total"
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>

    <Edit
      v-if="editShow"
      :isShow.sync="editShow"
      :getProjectList="projectList"
      :options="options"
    ></Edit>
    <el-dialog title="合同发票申请流程图" :visible.sync="dialogVisible" width="75%">
      <img src="@/assets/发票申请流程图.png" alt="合同发票申请流程图" width="100%" />
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    Edit: () => import('../dlg/Edit.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    NumberRangeInput: () => import('@/components/number-range-input.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  data() {
    return {
      loading: false, // 表格 加载
      // 查询条件
      parameter: {
        projectId: null,
        agreementId: null,
        userName: null,
        deptId: null,
        companyType: null,
        customersUnit: null,
        expenseNumber: null,
        actStatus: null,
        startCost: null,
        endCost: null,
        invoiceLines: null,
        offsetStatus: null,
        pageNow: 1,
        pageSize: 50,
        total: 0,
        isUninitiatedProjectInvoice: false,
      },
      numberRange: [],
      pageSize: 0,
      tableData: [], // 表格数据
      companyType: [],
      invoiceLines: [],
      offsetStatus: [],
      projectList: [], // 项目数据
      agreementList: [], // 合同数据
      editShow: false, // "编辑 -- 组件" 隐藏 或 显示
      deptList: [], // 部门 列表
      createdDate: [], //搜索框绑定日期
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
      options: {},
      dialogVisible: false, //流程弹窗
      exportingLoading: false,
    }
  },
  provide() {
    return {
      re: this.closeSubgroup,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  filters: {
    applyAmount(n) {
      if (n) {
        return Number(n).toLocaleString()
      } else {
        return n
      }
    },
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getProjectList() /** 加载 "项目/合同" 数据invoice **/
    this.getDeptList() /** 加载 "部门" 列表数据 **/
    this.getTableData() /** 加载 "发票申请" 数据 **/
  },
  watch: {
    'parameter.applyType': {
      handler: function (newVal, oldVal) {
        if (newVal) {
          this.parameter.pageNow = 1
          this.getTableData()
        }
      },
    },
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },
  methods: {
    exportingFn() {
      this.exportingLoading = true
      this.$api.invoice
        .invoiceApplicationListExport(this.parameter)
        .then(res => {
          const fileName = '合同发票申请明细.xlsx'
          const blob = new Blob([res], {
            type: 'application/vnd.ms-excel',
          })
          if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName)
          } else {
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = fileName
            link.click()
            URL.revokeObjectURL(link.href)
          }
          this.exportingLoading = false
          this.$message({
            message: '导出成功!',
            type: 'success',
          })
        })
        .catch(e => {
          this.exportingLoading = false
          console.log(e)
        })
    },
    /** 加载 "项目/合同" 数据 **/
    getProjectList() {
      this.$api.dict
        .listSysDictData('INVOICE_LINES', true)
        .then(res => {
          this.invoiceLines = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('OFFSET_STATUS', true)
        .then(res => {
          this.offsetStatus = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.dict
        .listSysDictData('COMPANY_TYPE_FULL', true)
        .then(res => {
          this.companyType = res.data
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.project
        .projectList({
          pageSize: 10000,
          pageNow: 1,
        })
        .then(res => {
          this.projectList = res.data ? res.data : []
        })
        .catch(err => {
          console.log(err)
        })
      this.$api.agreement
        .listByProjectByStaffToNam({
          pageSize: 10000,
          pageNow: 1,
        })
        .then(res => {
          this.agreementList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    /** 加载 "部门" 列表数据 **/
    getDeptList() {
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data
        })
        .catch(err => {
          console.log(err)
        })
    },
    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.projectId = null
      this.parameter.agreementId = null
      this.parameter.userName = null
      this.parameter.deptId = null
      this.parameter.customersUnit = null
      this.parameter.companyType = null
      this.parameter.expenseNumber = null
      this.parameter.startDate = null
      this.parameter.endDate = null
      this.parameter.actStatus = null
      this.parameter.startCost = null
      this.parameter.endCost = null
      this.parameter.invoiceLines = null
      this.parameter.offsetStatus = null
      this.createdDate = []
      this.numberRange = []
      this.parameter.pageNow = 1

      this.getTableData() /** 加载 "发票" 数据 **/
    },
    /** 加载 "发票申请" 数据 **/
    getTableData() {
      this.loading = true

      if (this.createdDate && this.createdDate != []) {
        this.parameter.startDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null) {
        this.parameter.startDate = null
        this.parameter.endDate = null
      }
      if (this.numberRange && this.numberRange.length > 0) {
        this.parameter.startCost = this.numberRange[0]
        this.parameter.endCost = this.numberRange[1]
      } else {
        this.parameter.startCost = null
        this.parameter.endCost = null
      }
      this.$api.invoice
        .invoiceApplicationAllList(this.parameter)
        .then(res => {
          this.tableData = res.data?.records
          this.parameter.total = res.data?.total
          this.loading = false
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    /**
     * 点击 "表格 -- 详情" 操作
     * @param item      当前数据
     */
    handleDetails(item) {
      this.options = item
      this.options.disabled = true
      this.options.isPrint = true
      this.options.isExport = true
      this.options.isAddAuditor = true
      this.options.title = '发票详情'
      this.editShow = true
    },

    /**
     * 点击 "表格 -- 分页" 操作
     * @param page      当前页
     */
    pageChangeHandler(page) {
      this.parameter.pageNow = page
      this.getTableData() /** 加载 "发票申请" 数据 **/
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getTableData()
    },
    handlePageSize() {
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getTableData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    /** 关闭 "编辑" 组件 **/
    closeSubgroup() {
      this.editShow = false
      this.getTableData() /** 加载 "发票申请" 数据 **/
    },
  },
}
</script>

<style scoped>
/* .mainList .mainList_content .mainList_content_sub .el-table {
  height: calc(100% - 93px);
} */
</style>
